import React from "react";
import homePageImage from './assets/KundaSplash.png';

import './styles.scss';
// import NavBar from "../../components/NavBar/NavBar";
import { Container } from "@mui/material";

export class HomePage extends React.Component {
    render() {
        return (
            <div className="HomePage">
                {/* <NavBar page="HomePage" /> */}
                <Container>
                    <img className="KundaMainImage" src={homePageImage} alt="" />
                    <h2 className="HomePageSubHeaderText">Coming Soon</h2>
                </Container>
            </div>
        )
    };
};

